module.exports = {
  siteTitle: 'Marty Andolino', // <title>
  manifestName: 'Eventually',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/feature_happy_marty.jpg',
  // social
  heading: 'Marty Andolino',
  subHeading:
    "Technologist. Problem Solver. Tinkerer.",
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/drsmartman',
    },
    {
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/in/marty-andolino-51a8131/',
    },
  ],
};
